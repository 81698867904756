import React, { useCallback } from "react";
import { useGoogleLogin } from "react-google-login";

import Button from "../../../../base/components/Button";

import { IS_GOOGLE } from "../../constants";
import {BUTTON_COLORS, BUTTON_STYLES} from "../../../../base/components/Button/appearance";
import PropTypes from "prop-types"

export const GoogleLoginButton = ({
  isDisabled,
  onPending,
  onSuccess,
  onFail,
  children,
}) => {
  const onSuccessLogin = useCallback(
    (res) => {
      onSuccess(res.accessToken, IS_GOOGLE);
      onPending(true);
    },
    [onPending, onSuccess]
  );

  const onFailure = useCallback(
    (error) => {
      onFail(error);
      onPending(true);
    },
    [onPending, onFail]
  );

  const { signIn } = useGoogleLogin({
    onSuccess: onSuccessLogin,
    onFailure,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    isSignedIn: false,
    accessType: "offline",
  });

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      onPending(false);
      return signIn(event);
    },
    [onPending, signIn]
  );

  return (
    <Button
      color={BUTTON_COLORS.primary}
      block={BUTTON_STYLES.block}
      wavesEffect={BUTTON_STYLES.wavesEffect}
      wavesLight={BUTTON_STYLES.wavesLight}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </Button>
  );
};


GoogleLoginButton.propTypes = {
    isDisabled: PropTypes.bool,
    children: PropTypes.any,
    onPending: PropTypes.func,
    onSuccess: PropTypes.func,
    onFail: PropTypes.func,
};
