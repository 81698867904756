import React from 'react'
import PropTypes from 'prop-types';

const InfoTitle = ({ children }) => {
  return (
    <h1 className='mb-3'>{children}</h1>
  )
};

export default InfoTitle;

InfoTitle.propTypes = {
  children: PropTypes.any,
}
