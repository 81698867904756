import React from 'react'

import InfoContainer from '../components/InfoContainer';
import InfoTitle from '../components/InfoTitle';
import TextList from '../components/TextList';

import { cookiesPolicyData } from '../data/cookiesPolicyMok';

const CookiesPolicy = () => {
  return (
    <InfoContainer metaText="Cookies policy">
      <InfoTitle>Cookies policy</InfoTitle>
      {cookiesPolicyData.map(({ title, texts }) => (
        <TextList key={title} title={title} texts={texts} />
      ))}
    </InfoContainer>
  )
};

export default CookiesPolicy;
