import React from 'react'
import PropTypes from 'prop-types';

const TextList = ({ title, texts }) => {
  return (
    <div>
      <h3>{title}</h3>
      {texts.map((text) => (
        <p key={text}>{text}</p>
      ))}
    </div>    
  )
};

TextList.propTypes = {
  title: PropTypes.string,
  texts: PropTypes.array
}

export default TextList;