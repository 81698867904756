import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';

const FormWrapper = ({ title, children }) => {
  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle className="mb-4">{title}</CardTitle>
              {children}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

FormWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
}


export default FormWrapper;
