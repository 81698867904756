import * as yup from "yup";
import {USER_EMAIL, USER_PASSWORD} from "../../../../validation/user";

export const validationSchema = yup.object().shape({
    email: USER_EMAIL.required(),
    password: USER_PASSWORD.required(),
    confirmPassword: USER_PASSWORD.required().test(
        "passwords-match",
        "Passwords must match",
        function (value) {
            return this.parent.password === value;
        }
    ),
});

export const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
};

