export const cookiesPolicyData = [
  {
    title: 'Introduction',
    texts: [
      'We will ask you to consent to our use of cookies in accordance with the terms of this policy when you first visit our website.'
    ]
  },
  {
    title: 'About cookies',
    texts: [
      'A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.'
    ]
  }
];
