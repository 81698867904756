import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CardBody, Card, Alert } from "reactstrap";

import Logo from '../../components/Logo';
import AuthHeader from '../../components/Header';
import AuthCardContainer from '../../components/AuthCardContainer';

import profile from "../../assets/profile-img.png";
import { VERIFICATION_SUCCESS_MESSAGE, VERIFICATION_TOKEN_ERROR_MESSAGE, VIRIFICATION_ERROR_MESSAGE } from "../../../../base/constants/messages";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import {AUTH_GROUP_LINKS} from "../../config";
import {useLoading} from "../../../../base/hooks/useLoading";

const Verification = () => {
  const [isLoading, {registerPromise}] = useLoading();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  const { params: { email, token } } = useLocationQuery();

  const verifyToken = useCallback(async (values) => {
    try {
      const response = await authService.verifyUser(values);

      if (response) {
        setSuccessMessage(VERIFICATION_SUCCESS_MESSAGE);
      }
    } catch {
      setErrorMessage(VIRIFICATION_ERROR_MESSAGE);
    }
  }, [authService]);

  const checkValidToken = useCallback((values) => {
    registerPromise(
      authService.checkValidToken(values)
        .then(({data: {isValid}}) => {
          if (isValid) {
            return verifyToken(values);
          }
        })
        .catch(() => {
          setErrorMessage(VERIFICATION_TOKEN_ERROR_MESSAGE);
        })
    );
  }, [registerPromise, authService, verifyToken]);

  const resendVerificationLink = async () => {
    try {
      await authService.resendVerificationLink({ email });
      setSuccessMessage("New verification link has been sent to your email");
    } catch (error) {
      setErrorMessage(error?.message);
    }
  };

  useEffect(() => {
    checkValidToken({ email, token });
  }, []);

  return (
    <AuthCardContainer metaText="Verification">
      <Card className="overflow-hidden">
        <AuthHeader headerText="Verification" icon={profile} />
        <CardBody className="pt-0">
          <Logo />
          {isLoading && (
            <>
              {/* TODO Spinner */}
            </>
          )}
          {successMessage && (
            <Alert color='success'>{successMessage}</Alert>
          )}
          {errorMessage && (
            <Alert color='danger'>{errorMessage}</Alert>
          )}
          <button
            className="btn btn-primary w-100"
            onClick={resendVerificationLink}
            disabled={!email}
          >
            Resend verification link
          </button>
        </CardBody>
      </Card>
      <div className="mt-4 text-center">
        <p>
          Go back to{" "}
          <Link
            to={AUTH_GROUP_LINKS.LINK_LOGIN}
            className="fw-medium text-primary"
          >
            Login
          </Link>
        </p>
      </div>
    </AuthCardContainer>
  )
};

export default Verification;
