export const STRINGS = {
  SOCIAL_lOGIN: {
    loginByGoogleBtn: "Login with Google",
    loginByFacebookBtn: "Login with Facebook",
  },
  SOCIAL_REGISTER: {
    registerByGoogleBtn: "Register with Google",
    registerByFacebookBtn: "Register with Facebook",
  },
};
