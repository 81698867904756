import React, { useCallback } from 'react'
import { Formik } from 'formik';

import FormWrapper from '../../components/FormWrapper/index';
import FormikInput from '../../../../base/components/FormikInput';
import FormikSelect from '../../../../base/components/FormikSelect';
import FormikTextarea from '../../../../base/components/FormikTextarea';
import Button from '../../../../base/components/Button/index';
import FormikMultiSelect from '../../../../base/components/FormikMultiSelect';
import FormikDropzone from '../../../../base/components/FormikDropZone';

import ContactService from '../../../../services/ContactService';
import ToasterService from '../../../../services/ToastService';
import { useService } from '../../../../base/hooks/useService';
import {initialValues, validationSchema} from "./form";


const ContactUs = () => {

  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);
  /**
   * @type {ContactService}
   */
  const contactService = useService(ContactService);

  const sendContact = useCallback(async (values) => {
    try {
      await contactService.sendContactForm(values);
      toastr.success('Your request was sent!');
    } finally {
      // TODO
    }
  }, []);

  return (
    <FormWrapper title='Contact Us'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={sendContact}
      >
        {({ handleSubmit }) => (
          <form className="form-horizontal p-2" onSubmit={handleSubmit}>
            <FormikInput
              id="firstName"
              name="firstName"
              label="First name"
              containerClassName="mb-3"
              placeholder="Enter first name"
              type="text"
            />
            <FormikInput
              id="lastName"
              name="lastName"
              label="Last name"
              containerClassName="mb-3"
              placeholder="Enter last name"
              type="text"
            />
            <FormikInput
              id="email"
              name="email"
              label="Email"
              containerClassName="mb-3"
              placeholder="Enter email"
              type="email"
            />
            <FormikInput
              id="phone"
              name="phone"
              label="Phone number"
              containerClassName="mb-3"
              placeholder="Enter phone number"
              type="tel"
            />
            <FormikSelect
              id="typeOfProblem"
              name="typeOfProblem"
              label='Type of problem'
              containerClassName="mb-3"
            >
              <option value="designer">Designer</option>
              <option value="development">Developer</option>
              <option value="product">Product Manager</option>
            </FormikSelect>
            <FormikTextarea
              name='problemDescription'
              label='Problem description'
              rows="3"
              placeholder='Enter problem description'
            />
            <FormikMultiSelect
              name='multiSelectField'
              label='Choose'
              options={[
                { label: 'option1', value: 'option1' },
                { label: 'option2', value: 'option2' },
                { label: 'option3', value: 'option3' }
              ]}
            />
            <FormikDropzone
              name='file'
              label='Choose file'
            />
            <Button
              type='submit'
              color='primary'
            >
              Send
            </Button>
          </form>
        )}
      </Formik>
    </FormWrapper>
  )
};

export default ContactUs;
