import React from "react";
import PropTypes from "prop-types"

export const TimeSpinner = ({ isDisabled }) => {
  return (
    <>
      {isDisabled ? (
        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2" />
      ) : null}
    </>
  );
};

TimeSpinner.propTypes = {
    isDisabled: PropTypes.bool
};
