import React, { useCallback } from "react";

import DropzoneFile from "../DropzoneFile";

import { getImageDimensions } from "./helpers";
import { isAllowedExtension } from "../DropzoneFile/utils/isAllowedExtension";

import {
  DEFAULT_IMAGE,
  MIN_IMAGE_WIDTH,
  MIN_IMAGE_HEIGHT,
  ALLOWED_IMAGE_EXTENSIONS,
} from "./constants";
import PropTypes from "prop-types";

export const AvatarUploader = ({
  selectedImage,
  onChange,
  defaultImage = DEFAULT_IMAGE,
}) => {
  const onValidate = useCallback(async (file) => {
    const { width, height } = await getImageDimensions(file);
    const isValidSize = width >= MIN_IMAGE_WIDTH && height >= MIN_IMAGE_HEIGHT;

    if (!isValidSize) {
      file.status = "error";
      // TODO error message
    }

    if (!isAllowedExtension(file, ALLOWED_IMAGE_EXTENSIONS)) {
      file.status = "error";
      // TODO error message
    }
  }, []);

  return (
    <div className="avatar-wrapper">
      <div className="overlay" />
      {defaultImage ? (
        <img className="avatar-preview" src={selectedImage || defaultImage} />
      ) : null}
      <DropzoneFile
        className="avatar-uploader"
        onReciveFile={onChange}
        isDropContainer={false}
        onValidate={onValidate}
      />
    </div>
  );
};

AvatarUploader.propTypes = {
  selectedImage: PropTypes.any,
  onChange: PropTypes.func,
  defaultImage: PropTypes.string,
};
