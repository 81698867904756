export const getImageDimensions = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        resolve({ width, height });
      });
    });
  });
};
