export const privacyPolicyData = [
  {
    title: 'Area of Validity',
    texts: [
      'This Privacy Policy applies to all mobile software applications the Company makes available for download (the “Apps”) and the related website located at www.ayoo.app and/or any other website(s) owned or controlled by or on behalf of the Company (collectively, “Sites” and collectively with the Apps, the “Service”). The Company does not accept any guarantee for compliance with data protection regulations for external Apps or Sites that are linked to the Service.'
    ]
  },
  {
    title: 'Personal Information Collection',
    texts: [
      'We collect information, including Personal Information, when you: register to use the Service; log in with social networking credentials; submit information to us when you use the Service; and communicate with us. We also collect information, such as anonymous usage statistics, by using cookies, server logs, and other similar technology as you use the Service.'
    ]
  }
];
