import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import { TimeSpinner } from "./components/TimeSpinner";
import { GoogleLoginButton } from "./components/GoogleLoginButton";
import { FacebookLoginButton } from "./components/FacebookLoginButton";

import { STRINGS } from "./constants/Strings";

const { SOCIAL_lOGIN, SOCIAL_REGISTER } = STRINGS;

const SocialLogin = ({ onSuccess, onFail, isRegister }) => {
  const [isLoadingGoogleLogin, setIsLoadingGoogleLogin] = useState(true);
  const [isLoadingFacebookLogin, setIsLoadingFacebookLogin] = useState(true);

  const googleBtnTitle = isRegister
    ? SOCIAL_REGISTER.registerByGoogleBtn
    : SOCIAL_lOGIN.loginByGoogleBtn;
  const facebookBtnTitle = isRegister
    ? SOCIAL_REGISTER.registerByFacebookBtn
    : SOCIAL_lOGIN.loginByFacebookBtn;

  const onSuccessLogin = useCallback((token, socialType) => {
    onSuccess(token, socialType);
  }, []);

  const onFailLogin = useCallback((error) => onFail(error), []);

  return (
    <>
      <div className="mt-3">
        <GoogleLoginButton
          isDisabled={!isLoadingGoogleLogin || !isLoadingFacebookLogin}
          onPending={setIsLoadingGoogleLogin}
          onSuccess={onSuccessLogin}
          onFail={onFailLogin}
        >
          <TimeSpinner isDisabled={!isLoadingGoogleLogin} />
          {googleBtnTitle}
        </GoogleLoginButton>
      </div>
      <div className="mt-3">
        <FacebookLoginButton
          isDisabled={!isLoadingGoogleLogin || !isLoadingFacebookLogin}
          onPending={setIsLoadingFacebookLogin}
          onSuccess={onSuccessLogin}
        >
          <TimeSpinner isDisabled={!isLoadingFacebookLogin} />
          {facebookBtnTitle}
        </FacebookLoginButton>
      </div>
    </>
  );
};

SocialLogin.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  isRegister: PropTypes.bool,
};

export default SocialLogin;
