import React, { useCallback } from "react";
import FacebookLogin from "react-facebook-login";

import { IS_FACEBOOK } from "../../constants";
import PropTypes from "prop-types";

export const FacebookLoginButton = ({
  isDisabled,
  onPending,
  onSuccess,
  children,
}) => {
  const onClick = useCallback(() => onPending(false), [onPending]);

  const responseFacebook = useCallback(
    (res) => {
      onSuccess(res.accessToken, IS_FACEBOOK);
      onPending(true);
    },
    [onPending, onSuccess]
  );

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_ID}
      fields="name,email,picture"
      onClick={onClick}
      callback={responseFacebook}
      cssClass="btn btn-primary btn-block w-100 waves-effect waves-light"
      textButton={children}
      isDisabled={isDisabled}
      returnScopes
    />
  );
};


FacebookLoginButton.propTypes = {
    isDisabled: PropTypes.bool,
    children: PropTypes.any,
    onPending: PropTypes.func,
    onSuccess: PropTypes.func,
    onFail: PropTypes.func,
};
