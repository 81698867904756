import React from 'react'

import InfoContainer from '../components/InfoContainer';
import InfoTitle from '../components/InfoTitle';
import TextList from '../components/TextList';

import { privacyPolicyData } from '../data/privacyPolicyMok';

const PrivacyPolicy = () => {
  return (
    <InfoContainer metaText="Privacy policy">
      <InfoTitle>Privacy policy</InfoTitle>
      {privacyPolicyData.map(({ title, texts }) => (
        <TextList key={title} title={title} texts={texts} />
      ))}
    </InfoContainer>
  )
};

export default PrivacyPolicy;
